<template>
	<div>
		<footer id="footer_area">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-md-6 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("need_help") }}</h5>
						</div>
						<div class="footer_first_area">
							<div class="footer_inquery_area">
								<h5>{{ $t("available") }}</h5>
								<h3><a href="tel:+00-123-456-789">+57 604 256 3458</a></h3>
							</div>
							<div class="footer_inquery_area">
								<h5>{{ $t("send_email") }}</h5>
								<h3>
									<a href="mailto:support@domain.com">admin@selahcomfort.com</a>
								</h3>
							</div>
							<div class="footer_inquery_area">
								<h5>{{ $t("follow_us") }}</h5>
								<ul class="soical_icon_footer">
									<li>
										<a href="#!"><i class="fab fa-facebook"></i></a>
									</li>
									<li>
										<a href="#!"
											><i class="fa-brands fa-square-x-twitter"></i
										></a>
									</li>
									<li>
										<a href="#!"><i class="fab fa-instagram"></i></a>
									</li>
									<li>
										<a href="#!"><i class="fab fa-linkedin"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("us") }}</h5>
						</div>
						<div class="footer_link_area">
							<ul>
								<li>
									<router-link to="/about-us">{{ $t("about_us") }}</router-link>
								</li>
								<li>
									<router-link to="/testimonials">{{
										$t("testimonials")
									}}</router-link>
								</li>
								<!-- <li><router-link to="/faqs">Rewards</router-link></li>
								<li>
									<router-link to="/terms-service">Work with Us</router-link>
								</li>
								<li>
									<router-link to="/tour-guides">Meet the Team </router-link>
								</li>
								<li><router-link to="/news">Blog</router-link></li> -->
							</ul>
						</div>
					</div>
					<div class="col-lg-2 col-md-4 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("support") }}</h5>
						</div>
						<div class="footer_link_area">
							<ul>
								<li>
									<router-link to="/register-owner">{{
										$t("owner_registration")
									}}</router-link>
								</li>
								<li>
									<router-link to="/faqs">{{
										$t("frequently_asked_questions")
									}}</router-link>
								</li>
								<!-- <li><router-link to="/testimonials">Legal</router-link></li> -->
								<li>
									<router-link to="/contact">{{
										$t("contact_us")
									}}</router-link>
								</li>
								<!-- <li>
									<router-link to="/top-destinations">
										Affiliate Program</router-link
									>
								</li> -->
								<li>
									<router-link to="/privacy-policy">{{
										$t("privacy_policies")
									}}</router-link>
								</li>
							</ul>
						</div>
					</div>
					<!-- <div class="col-lg-2 col-md-4 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>Other Services</h5>
						</div>
						<div class="footer_link_area">
							<ul>
								<li>
									<router-link to="/top-destinations-details"
										>Community program</router-link
									>
								</li>
								<li>
									<router-link to="/top-destinations-details"
										>Investor Relations</router-link
									>
								</li>
								<li>
									<router-link to="/flight-search-result"
										>Rewards Program</router-link
									>
								</li>
								<li>
									<router-link to="/room-booking">PointsPLUS</router-link>
								</li>
								<li><router-link to="/testimonials">Partners</router-link></li>
								<li>
									<router-link to="/hotel-search">List My Hotel</router-link>
								</li>
							</ul>
						</div>
					</div> -->
					<div class="col-lg-2 col-md-4 col-sm-6 col-12">
						<div class="footer_heading_area">
							<h5>{{ $t("top_cities") }}</h5>
						</div>
						<div class="footer_link_area">
							<ul>
								<li><router-link to="#!">Medellín</router-link></li>
								<li><router-link to="#!">Bogotá</router-link></li>
								<li><router-link to="#!">San Andrés</router-link></li>
								<li><router-link to="#!">Barranquilla</router-link></li>
								<li><router-link to="#!">Santa Marta</router-link></li>
								<li><router-link to="#!">Cartagena</router-link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<div class="copyright_area">
			<div class="container">
				<div class="row align-items-center">
					<div class="co-lg-6 col-md-6 col-sm-12 col-12">
						<div class="copyright_left">
							<p>{{ $t("copyright") }}</p>
						</div>
					</div>
					<div class="co-lg-6 col-md-6 col-sm-12 col-12">
						<div class="copyright_right">
							<img src="../assets/img/common/cards.png" alt="img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Footer",
	};
</script>
