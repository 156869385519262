<template>
	<section id="theme_search_form" style="position: absolute-center; z-index: 2">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="theme_search_form_area">
						<div class="row">
							<div class="col-lg-12">
								<div class="tour_search_form">
									<form @submit.prevent="propertiesSearch">
										<div class="row">
											<div class="col-lg-6 col-md-12 col-sm-12 col-12">
												<div class="flight_Search_boxed">
													<p>{{ $t("destination") }}</p>
													<v-select
														v-model="selectedOption"
														label="text"
														:options="results.allOptions"
														:filterable="true"
														:placeholder="$t('where_do_you_want_to_go')"
														:key="selectedOption"
														@input="updateSelectedOptionId"
													></v-select>
												</div>
											</div>
											<div class="col-lg-4 col-md-6 col-sm-12 col-12">
												<div class="form_search_date">
													<div class="flight_Search_boxed date_flex_area">
														<div class="Journey_date">
															<p>{{ $t("entry_date") }}</p>
															<input type="date" v-model="start_date" />
														</div>
														<div class="Journey_date">
															<p>{{ $t("departure_date") }}</p>
															<input type="date" v-model="end_date" />
														</div>
													</div>
												</div>
											</div>
											<CountDropdownHotel />
											<div class="top_form_search_button">
												<button class="btn btn_theme btn_md">
													{{ $t("search") }}
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import CountDropdownHotel from "@/components/home/CountDropdownHotel.vue";
	import Swal from "sweetalert2";
	import axios from "axios";
	import { mapState, mapActions } from "vuex";
	import { API_BASE_URL, COUNTRY_STATE_CITY_API } from "@/api-config";

	export default {
		name: "Form",
		activated() {
			this.selectedOption = this.destination;
		},
		components: {
			CountDropdownHotel,
		},
		props: {
			props_destination: String,
			props_start_date: Date,
			props_end_date: Date,
			props_capacity: Number,
			props_city_id: Number,
			props_state_id: Number,
			props_country_id: Number,
		},
		data() {
			return {
				destination: "",
				active: false,
				results: [],
				start_date: "",
				end_date: "",
				state_id: 0,
				country_id: 0,
				results: {
					cities: [],
					states: [],
					countries: [],
					allOptions: [],
				},
				selectedOption: "",
			};
		},
		mounted() {
			this.getCountryStateCity();
			this.destination = this.props_destination;
			this.start_date = this.props_start_date;
			this.end_date = this.props_end_date;
			this.city_id = this.props_city_id;
			this.state_id = this.props_state_id;
			this.country_id = this.props_country_id;
		},
		computed: {
			...mapState({
				capacity: (state) => state.form.capacity,
				error: (state) => state.form.error,
			}),
		},
		methods: {
			...mapActions("form", ["fetchProperties", "updateError"]),

			showAlert(message, type) {
				Swal.fire({
					icon: type,
					title: type === "success" ? "Éxito" : "Error",
					text: message,
				}).then((confirm) => {
					this.updateError({ status: false, message: "" });
				});
			},

			getCountryStateCity() {
				axios
					.get(`${API_BASE_URL}${COUNTRY_STATE_CITY_API}`)
					.then((response) => {
						this.results.cities = response.data.cities;
						this.results.states = response.data.states;
						this.results.countries = response.data.countries;

						this.results.allOptions = [
							...this.results.cities,
							...this.results.states,
							...this.results.countries,
						];
					})
					.catch((error) => {
						console.log(error);
					});
			},
			updateSelectedOptionId(selectedOption) {
				if (selectedOption) {
					this.selectedOptionId = selectedOption.id;
					this.destination = selectedOption.text;
				} else {
					this.selectedOptionId = null;
					this.destination = "";
				}
			},
			async propertiesSearch() {
				let queryParameters = {
					destination: this.selectedOption.text,
					start_date: this.start_date,
					end_date: this.end_date,
					capacity: this.capacity,
				};

				if (this.selectedOption) {
					const optionTypes = ["cities", "states", "countries"];
					const optionType = optionTypes.find((type) =>
						this.results[type].includes(this.selectedOption)
					);
					if (optionType) {
						const keyMapping = {
							cities: "city_id",
							states: "state_id",
							countries: "country_id",
						};
						queryParameters[keyMapping[optionType]] = this.selectedOption.id;
					}
				}
				await this.fetchProperties(queryParameters);

				if (this.error.status == true) {
					this.showAlert(this.error.message, "error");
				} else {
					this.$router.push({
						name: "hotel-search",
						query: queryParameters,
					});
				}
			},
		},
	};
</script>
