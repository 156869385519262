<template>
	<!-- Common Banner Area -->
	<Banner />

	<!-- Contact Area -->
	<ContactArea />
</template>
<script>
	import Banner from "@/components/owner/Banner.vue";
	import ContactArea from "@/components/owner/RegisterForm.vue";
	export default {
		name: "RegisterOwnerView",
		components: {
			Banner,
			ContactArea,
		},
	};
</script>
