import { createI18n } from "vue-i18n";

const messages = {
	es: require("@/translates/es.json"),
	en: require("@/translates/en.json"),
};

const i18n = createI18n({
	locale: "es",
	fallbackLocale: "en",
	messages,
});

export default i18n;
