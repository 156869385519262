<template>
	<div>
		<header class="main_header_arae">
			<!-- Top Bar -->
			<div class="topbar-area">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-6 col-md-6">
							<ul class="topbar-list">
								<li>
									<a href="#!"><i class="fab fa-facebook"></i></a>
									<a href="#!">
										<i class="fa-brands fa-square-x-twitter"></i
									></a>
									<a href="#!"><i class="fab fa-instagram"></i></a>
									<a href="#!"><i class="fab fa-linkedin"></i></a>
								</li>
								<li>
									<a href="#!"><span>+57 604 256 3458</span></a>
								</li>
								<li>
									<a href="#!"><span>admin@selahcomfort.com</span></a>
								</li>
							</ul>
						</div>
						<div class="col-lg-6 col-md-6">
							<ul class="topbar-others-options">
								<li>
									<router-link to="#!">{{ $t("login") }}</router-link>
								</li>
								<li>
									<router-link to="#!">{{ $t("sign_up") }}</router-link>
								</li>
								<li>
									<div class="dropdown language-option">
										<select v-model="language" @change="changeLanguage">
											<option value="es">Español</option>
											<option value="en">English</option>
										</select>
									</div>
								</li>
								<!-- <li>
									<div class="dropdown language-option">
										<select v-model="currency">
											<option value="USD">USD</option>
											<option value="COP">COP</option>
										</select>
									</div>
								</li> -->
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- Navbar Bar -->
			<div class="navbar-area" :class="{ 'is-sticky': isSticky }">
				<div class="main-responsive-nav">
					<div class="container">
						<div class="main-responsive-menu mean-container">
							<nav class="navbar">
								<div class="container">
									<div class="logo">
										<router-link to="/">
											<img src="../assets/img/logo.png" alt="logo" />
										</router-link>
									</div>
									<button
										class="navbar-toggler collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#navbar-content"
									>
										<div class="hamburger-toggle">
											<div class="hamburger">
												<span></span>
												<span></span>
												<span></span>
											</div>
										</div>
									</button>
									<div
										class="collapse navbar-collapse mean-nav"
										id="navbar-content"
									>
										<ul class="navbar-nav mr-auto mb-2 mb-lg-0">
											<li class="nav-item dropdown">
												<router-link
													to="/"
													class="dropdown-item dropdown-toggle"
													>{{ $t("home") }}</router-link
												>
											</li>
											<li class="nav-item">
												<router-link to="#" class="dropdown-item">{{
													$t("clients")
												}}</router-link>
											</li>
											<li class="nav-item">
												<router-link to="/about-us" class="dropdown-item">{{
													$t("about_us")
												}}</router-link>
											</li>
											<li class="nav-item">
												<router-link to="/contact" class="dropdown-item">{{
													$t("contact_us")
												}}</router-link>
											</li>
										</ul>
									</div>
								</div>
							</nav>
						</div>
					</div>
				</div>
				<div class="main-navbar">
					<div class="container">
						<nav class="navbar navbar-expand-md navbar-light">
							<router-link class="navbar-brand" to="/">
								<img src="../assets/img/logo.png" alt="logo" />
							</router-link>

							<div
								class="collapse navbar-collapse mean-menu"
								id="navbarSupportedContent"
							>
								<ul class="navbar-nav">
									<li class="nav-item">
										<router-link to="/" class="nav-link">
											{{ $t("home") }}
										</router-link>
									</li>
									<li class="nav-item">
										<router-link to="#" class="nav-link">{{
											$t("clients")
										}}</router-link>
									</li>
									<li class="nav-item">
										<router-link to="/about-us" class="nav-link">{{
											$t("about_us")
										}}</router-link>
									</li>
									<li class="nav-item">
										<router-link to="/contact" class="nav-link">{{
											$t("contact_us")
										}}</router-link>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
				<div class="others-option-for-responsive">
					<div class="container">
						<div class="container">
							<div class="option-inner">
								<div class="others-options d-flex align-items-center">
									<div class="option-item">
										<a href="#" class="search-box"
											><i class="fas fa-search"></i
										></a>
									</div>
									<div class="option-item">
										<router-link to="/contact" class="btn btn_navber"
											>Get free quote</router-link
										>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	</div>
</template>
<script>
	import { RouterLink } from "vue-router";
	import i18n from "./../translates/translationService";
	export default {
		name: "Header",
		data() {
			return {
				language: "es",
				currency: "COP",
				isSticky: false,
			};
		},

		mounted() {
			let lang = localStorage.getItem("lang");
			if (lang == undefined) {
				localStorage.setItem("lang", "es");
				lang = "es";
			}
			this.language = lang;
			this.$i18n.locale = this.language;

			window.addEventListener("scroll", () => {
				let scroll = window.scrollY;
				if (scroll >= 200) {
					this.isSticky = true;
				} else {
					this.isSticky = false;
				}
			});

			document.addEventListener("click", function (e) {
				// Hamburger menu
				if (e.target.classList.contains("hamburger-toggle")) {
					e.target.children[0].classList.toggle("active");
				}
			});
		},
		methods: {
			changeLanguage() {
				if (this.$i18n) {
					this.$i18n.locale = this.language;
					localStorage.setItem("lang", this.language);
				}
			},
		},
	};
</script>
