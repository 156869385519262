<template>
	<!-- Common Banner Area -->
	<HotelDetailBanner />

	<!-- Hotel Search Areas -->
	<HotelSearch :hotelId="hotelId" />

	<!-- Testimonials Area -->
	<Testimonials />

	<!--Related hotel packages Area -->
	<RecommendedProperties />

	<!-- Cta Area -->
	<!-- <Cta /> -->
</template>
<script>
	import HotelDetailBanner from "@/components/hotel/HotelDetailBanner.vue";
	import HotelSearch from "@/components/hotel/HotelSearch.vue";
	import RecommendedProperties from "@/components/home/RecommendedProperties.vue";
	import Testimonials from "@/components/pages/Testimonials.vue";
	// import Cta from "@/components/home/Cta.vue";

	export default {
		name: "HotelDetailsView",
		components: {
			HotelDetailBanner,
			HotelSearch,
			Testimonials,
			RecommendedProperties,
			// Cta,
		},
		data() {
			return {
				hotelId: this.$route.params.id,
			};
		},
	};
</script>
