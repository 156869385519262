<template>
	<Header />

	<router-view :key="$route.params.id" />

	<Footer />
</template>

<script>
	import Header from "@/components/Header.vue";
	import Footer from "@/components/Footer.vue";

	export default {
		name: "App",
		components: {
			Header,
			Footer,
		},
	};
</script>
