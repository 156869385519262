<template>
	<section id="go_beyond_area" class="section_padding_top">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="heading_left_area">
						<h2>{{ $t("special_offers") }}</h2>
						<h5>{{ $t("discover_an_ideal_experience_with_us") }}</h5>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="imagination_boxed">
						<img
							src="../../assets/img/imagination/imagination1.png"
							alt="img"
						/>
						<h3>
							<router-link to="/#!">{{
								$t("special_discount_for_all_hotels")
							}}</router-link>
						</h3>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="imagination_boxed">
						<img
							src="../../assets/img/imagination/imagination2.png"
							alt="img"
						/>
						<h3>
							<router-link to="/#!">{{
								$t("travel_around_the_world")
							}}</router-link>
						</h3>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12">
					<div class="imagination_boxed">
						<img
							src="../../assets/img/imagination/imagination3.png"
							alt="img"
						/>

						<h3>
							<router-link to="/#!">{{
								$t("luxury_resort_best_deals")
							}}</router-link>
						</h3>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		name: "Imagination",
	};
</script>
