export default {
	tourData: [
		{
			id: 1,
			tourImage: "hotel1",
			altText: "image",
			location: "New beach, Thailand",
			tourTitle: "Kantua hotel, Thailand",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 2,
			tourImage: "hotel2",
			altText: "image",
			location: "Indonesia",
			tourTitle: "Hotel paradise international",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 3,
			tourImage: "hotel3",
			altText: "image",
			location: "Kualalampur",
			tourTitle: "Hotel kualalampur",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 4,
			tourImage: "hotel4",
			altText: "image",
			location: "Mariana island",
			tourTitle: "Hotel deluxe",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 5,
			tourImage: "hotel5",
			altText: "image",
			location: "Kathmundu, Nepal",
			tourTitle: "Hotel rajavumi",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 6,
			tourImage: "hotel6",
			altText: "image",
			location: "Beach view",
			tourTitle: "Thailand grand suit",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 7,
			tourImage: "hotel7",
			altText: "image",
			location: "Long island",
			tourTitle: "Zefi resort and spa",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 8,
			tourImage: "hotel8",
			altText: "image",
			location: "Philippine",
			tourTitle: "Manila international resort",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 9,
			tourImage: "hotel1",
			altText: "image",
			location: "New beach, Thailand",
			tourTitle: "Kantua hotel, Thailand",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
	],

	hotelData: [
		{
			id: 1,
			tourImage: "hotel1",
			altText: "image",
			location: "New beach, Thailand",
			tourTitle: "Kantua hotel, Thailand",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 2,
			tourImage: "hotel2",
			altText: "image",
			location: "Indonesia",
			tourTitle: "Hotel paradise international",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 3,
			tourImage: "hotel3",
			altText: "image",
			location: "Kualalampur",
			tourTitle: "Hotel kualalampur",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 4,
			tourImage: "hotel4",
			altText: "image",
			location: "Mariana island",
			tourTitle: "Hotel deluxe",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 5,
			tourImage: "hotel5",
			altText: "image",
			location: "Kathmundu, Nepal",
			tourTitle: "Hotel rajavumi",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 6,
			tourImage: "hotel6",
			altText: "image",
			location: "Beach view",
			tourTitle: "Thailand grand suit",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 7,
			tourImage: "hotel7",
			altText: "image",
			location: "Long island",
			tourTitle: "Zefi resort and spa",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 8,
			tourImage: "hotel8",
			altText: "image",
			location: "Philippine",
			tourTitle: "Manila international resort",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 9,
			tourImage: "hotel1",
			altText: "image",
			location: "New beach, Thailand",
			tourTitle: "Kantua hotel, Thailand",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},

		{
			id: 10,
			tourImage: "hotel1",
			altText: "image",
			location: "New beach, Thailand",
			tourTitle: "Kantua hotel, Thailand",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 11,
			tourImage: "hotel2",
			altText: "image",
			location: "Indonesia",
			tourTitle: "Hotel paradise international",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 12,
			tourImage: "hotel3",
			altText: "image",
			location: "Kualalampur",
			tourTitle: "Hotel kualalampur",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
	],

	flightData: [
		{
			id: 1,
			flightImage: "biman_bangla",
			arrowImage: "right_arrow",
			form: "From",
			location: "New York",
			terminal1: "JFK - John F. Kennedy International...",
			stopes: "Non-stop",
			time: "01h 05minute ",
			to: "To",
			destination: "London",
			terminal2: "LCY, London city airport",
			discuntPrice: "$9,560",
			offerPrice: "$7,560",
			offrePercentage: "*20% OFF",
			bookNow: "Book now",
			discuntInfo: "*Discount applicable on some conditions",
			showMore: "Show more",

			moreFlightInfo: [
				{
					flightId: 101,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 102,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 103,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
			],
		},
		{
			id: 2,
			flightImage: "biman_bangla",
			arrowImage: "right_arrow",
			form: "From",
			location: "New York",
			terminal1: "JFK - John F. Kennedy International...",
			stopes: "Non-stop",
			time: "01h 05minute ",
			to: "To",
			destination: "London",
			terminal2: "LCY, London city airport",
			discuntPrice: "$9,560",
			offerPrice: "$7,560",
			offrePercentage: "*20% OFF",
			bookNow: "Book now",
			discuntInfo: "*Discount applicable on some conditions",
			showMore: "Show more",

			moreFlightInfo: [
				{
					flightId: 101,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 102,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 103,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
			],
		},
		{
			id: 3,
			flightImage: "biman_bangla",
			arrowImage: "right_arrow",
			form: "From",
			location: "New York",
			terminal1: "JFK - John F. Kennedy International...",
			stopes: "Non-stop",
			time: "01h 05minute ",
			to: "To",
			destination: "London",
			terminal2: "LCY, London city airport",
			discuntPrice: "$9,560",
			offerPrice: "$7,560",
			offrePercentage: "*20% OFF",
			bookNow: "Book now",
			discuntInfo: "*Discount applicable on some conditions",
			showMore: "Show more",

			moreFlightInfo: [
				{
					flightId: 101,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 102,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 103,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
			],
		},

		{
			id: 4,
			flightImage: "biman_bangla",
			arrowImage: "right_arrow",
			form: "From",
			location: "New York",
			terminal1: "JFK - John F. Kennedy International...",
			stopes: "Non-stop",
			time: "01h 05minute ",
			to: "To",
			destination: "London",
			terminal2: "LCY, London city airport",
			discuntPrice: "$9,560",
			offerPrice: "$7,560",
			offrePercentage: "*20% OFF",
			bookNow: "Book now",
			discuntInfo: "*Discount applicable on some conditions",
			showMore: "Show more",

			moreFlightInfo: [
				{
					flightId: 101,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 102,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
				{
					flightId: 103,
					id: 1,
					flightName: "Biman Bangladesh Airlines 123",
					flightNumber: "BOEING 737-800 - 738",
				},
			],
		},
	],

	testimonialsData: [
		{
			date: "08 Dec, 2021",
			clientName: "Michel falak",
			review: "Excellent",
			reviewMessage:
				"Me encantó el recorrido general durante los 6 días que cubrieron Jaipur, Jodhpur y Jaisalmer. Sin duda, valió la pena. Gracias. El conductor fue muy bueno, cortés y condujo de manera segura durante los 6 días. Recogida y regreso a tiempo en general. Gracias por eso.",
			clientImage: "review1",
			altText: "image",
		},
		{
			date: "08 Dec, 2021",
			clientName: "Chester dals",
			review: "Excellent",
			reviewMessage:
				"Me encantó el recorrido general durante los 6 días que cubrieron Jaipur, Jodhpur y Jaisalmer. Sin duda, valió la pena. Gracias. El conductor fue muy bueno, cortés y condujo de manera segura durante los 6 días. Recogida y regreso a tiempo en general. Gracias por eso.",
			clientImage: "review2",
			altText: "image",
		},
		{
			date: "08 Dec, 2021",
			clientName: "Casper mike",
			review: "Excellent",
			reviewMessage:
				"Me encantó el recorrido general durante los 6 días que cubrieron Jaipur, Jodhpur y Jaisalmer. Sin duda, valió la pena. Gracias. El conductor fue muy bueno, cortés y condujo de manera segura durante los 6 días. Recogida y regreso a tiempo en general. Gracias por eso.",
			clientImage: "review3",
			altText: "image",
		},
		{
			date: "08 Dec, 2021",
			clientName: "Jason bruno",
			review: "Excellent",
			reviewMessage:
				"Me encantó el recorrido general durante los 6 días que cubrieron Jaipur, Jodhpur y Jaisalmer. Sin duda, valió la pena. Gracias. El conductor fue muy bueno, cortés y condujo de manera segura durante los 6 días. Recogida y regreso a tiempo en general. Gracias por eso.",
			clientImage: "review4",
			altText: "image",
		},
		{
			date: "08 Dec, 2021",
			clientName: "Michel falak",
			review: "Excellent",
			reviewMessage:
				"Me encantó el recorrido general durante los 6 días que cubrieron Jaipur, Jodhpur y Jaisalmer. Sin duda, valió la pena. Gracias. El conductor fue muy bueno, cortés y condujo de manera segura durante los 6 días. Recogida y regreso a tiempo en general. Gracias por eso.",
			clientImage: "review5",
			altText: "image",
		},
		{
			date: "08 Dec, 2021",
			clientName: "Chester dals",
			review: "Excellent",
			reviewMessage:
				"Me encantó el recorrido general durante los 6 días que cubrieron Jaipur, Jodhpur y Jaisalmer. Sin duda, valió la pena. Gracias. El conductor fue muy bueno, cortés y condujo de manera segura durante los 6 días. Recogida y regreso a tiempo en general. Gracias por eso.",
			clientImage: "review6",
			altText: "image",
		},
	],

	tourGuides: [
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-1",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-2",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-3",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-4",
			altText: "image",
		},
	],

	tourGuides2: [
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-5",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-6",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-7",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-8",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-9",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-10",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-11",
			altText: "image",
		},
		{
			memberName: "Anna naomi",
			designation: "CTO",
			memberImage: "guide-12",
			altText: "image",
		},
	],

	topDestinationData: [
		{
			id: 1,
			topDestinationImage: "main-page1",
			altText: "image",
			location: "Ancient egypt",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 2,
			topDestinationImage: "main-page2",
			altText: "image",
			location: "Ancient egypt",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 3,
			topDestinationImage: "main-page3",
			altText: "image",
			location: "Ancient egypt",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 4,
			topDestinationImage: "main-page4",
			altText: "image",
			location: "Ancient egypt",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 5,
			topDestinationImage: "main-page5",
			altText: "image",
			location: "Ancient egypt",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
		{
			id: 6,
			topDestinationImage: "main-page6",
			altText: "image",
			location: "Ancient egypt",
			reviewRating: "4.8/5 Excellent",
			reviewCount: "1214 reviewes",
			price: "$99.00",
			startForm: "Price starts from",
		},
	],
};
