<template>
	<section id="explore_area" class="section_padding">
		<div class="container">
			<!-- Section Heading -->
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="section_heading_center">
						<h2>{{ $t("properties_found") }}</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-3">
					<div class="left_side_search_area">
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_price") }}</h5>
							</div>
							<div class="form-group">
								{{ $t("from") }}
								<input
									type="number"
									class="form-control bg_input"
									v-model="min"
									@input="validateInput"
								/>
							</div>
							<br />
							<div class="form-group">
								{{ $t("to") }}
								<input
									type="number"
									class="form-control bg_input"
									v-model="max"
									@input="validateInput"
								/>
							</div>
							<button class="apply" type="button" @click="applyPriceFilters">
								<br />{{ $t("apply") }}
							</button>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_review") }}</h5>
							</div>
							<div class="filter_review">
								<form class="review_star">
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="5"
											id="flexCheckDefault"
											v-model="reviewFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefault">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="4"
											id="flexCheckDefault1"
											v-model="reviewFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefault1">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="3"
											id="flexCheckDefault2"
											v-model="reviewFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefault2">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="2"
											id="flexCheckDefault3"
											v-model="reviewFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefault3">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="1"
											id="flexCheckDefault5"
											v-model="reviewFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefault5">
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
								</form>
							</div>
						</div>
						<div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>{{ $t("filter_by_hotel_star") }}</h5>
							</div>
							<div class="filter_review">
								<form class="review_star">
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="5"
											id="flexCheckDefaulta"
											v-model="scoreFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefaulta">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="4"
											id="flexCheckDefaulf21"
											v-model="scoreFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefaulf2">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="3"
											id="flexCheckDefaultf3"
											v-model="scoreFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefaultf3">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="2"
											id="flexCheckDefaultf4"
											v-model="scoreFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefaultf4">
											<i class="fas fa-star color_theme"></i>
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value="1"
											id="flexCheckDefaultf5"
											v-model="scoreFilter"
											@change="applyFilters"
										/>
										<label class="form-check-label" for="flexCheckDefaultf5">
											<i class="fas fa-star color_theme"></i>
										</label>
									</div>
								</form>
							</div>
						</div>
						<!-- <div class="left_side_search_boxed">
							<div class="left_side_search_heading">
								<h5>Comodidades</h5>
							</div>
							<div class="tour_search_type">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefaultt1"
									/>
									<label class="form-check-label" for="flexCheckDefaultt1">
										<span class="area_flex_one">
											<span>Gimnasio</span>
											<span>20</span>
										</span>
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefaultt2"
									/>
									<label class="form-check-label" for="flexCheckDefaultt2">
										<span class="area_flex_one">
											<span>Bikefriendly</span>
											<span>14</span>
										</span>
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefaultt3"
									/>
									<label class="form-check-label" for="flexCheckDefaultt3">
										<span class="area_flex_one">
											<span>Internet, Wifi</span>
											<span>62</span>
										</span>
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefaultt4"
									/>
									<label class="form-check-label" for="flexCheckDefaultt4">
										<span class="area_flex_one">
											<span>Salon de aeróbicos</span>
											<span>08</span>
										</span>
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefaultt5"
									/>
									<label class="form-check-label" for="flexCheckDefaultt5">
										<span class="area_flex_one">
											<span>Jaulas de golf</span>
											<span>12</span>
										</span>
									</label>
								</div>
							</div>
						</div> -->
					</div>
				</div>
				<div class="col-lg-9">
					<div class="row">
						<div
							v-for="(hotelInfo, index) in filteredItems"
							:key="index"
							class="col-lg-4 col-md-6 col-sm-6 col-12"
						>
							<div class="theme_common_box_two img_hover">
								<div class="theme_two_box_img">
									<router-link
										:to="{
											name: `hotel-details`,
											params: { id: hotelInfo.id },
											query: {
												start_date: $route.query.start_date,
												end_date: $route.query.end_date,
												capacity: $route.query.capacity,
											},
										}"
									>
										<img :src="hotelInfo.imagen" :alt="hotelInfo.name" />
									</router-link>
									<p>
										<i class="fas fa-map-marker-alt"></i>{{ hotelInfo.address }}
									</p>
								</div>

								<div class="theme_two_box_content">
									<h4>
										<router-link
											:to="{
												name: `hotel-details`,
												params: { id: hotelInfo.id },
												query: {
													start_date: $route.query.start_date,
													end_date: $route.query.end_date,
													capacity: $route.query.capacity,
												},
											}"
											>{{ hotelInfo.name }}</router-link
										>
									</h4>
									<p>
										<span class="review_rating"
											><div class="review_star_all">
												<span v-show="hotelInfo.scores > 0">
													{{ $t("category") }}
													<i
														v-show="hotelInfo.scores > 0"
														class="fas fa-star"
														v-for="n in hotelInfo.scores"
														:key="n"
													></i>
												</span>
												<span
													v-show="hotelInfo.reviews > 0"
													class="review_count"
													>{{ $t("review") }}
													<i
														class="fas fa-star"
														v-for="m in hotelInfo.reviews"
														:key="m"
													></i
												></span>
											</div>
											<!-- <div class="review_star_all">
												Reseña
												<i
													class="fas fa-star"
													v-for="n in hotelInfo.reviews"
													:key="n"
												></i></div> -->
										</span>
									</p>
									<h3>
										<span> {{ $t("prices_from") }} </span
										>{{ formatNumber(hotelInfo.price) }}
									</h3>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="pagination_area">
								<ul class="pagination">
									<li
										class="page-item"
										:class="{ disabled: currentPage === 1 }"
									>
										<a
											class="page-link"
											@click="prev(currentPage - 1)"
											:disabled="currentPage === 1"
											aria-label="Previous"
										>
											<span aria-hidden="true">&laquo;</span>
											<span class="sr-only">Previous</span>
										</a>
									</li>
									<li
										class="page-item"
										v-for="page in paginationLinks.pages"
										:key="page.label"
										:class="{ active: page.active }"
									>
										<a
											class="page-link"
											@click="next(page.label)"
											v-if="page.url !== null"
											>{{ page.label }}</a
										>
										<span class="page-link" v-else>{{ page.label }}</span>
									</li>
									<li
										class="page-item"
										:class="{ disabled: currentPage === totalPages }"
									>
										<a
											class="page-link"
											@click="next(currentPage + 1)"
											:disabled="currentPage === totalPages"
											aria-label="Next"
										>
											<span aria-hidden="true">&raquo;</span>
											<span class="sr-only">Next</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import axios from "axios";
	import Slider from "@vueform/slider";
	import { mapState, mapActions } from "vuex";

	export default {
		name: "SearchResult",
		components: {
			Slider,
		},
		computed: {
			...mapState({
				paginationLinks: (state) => state.form.paginationLinks,
				currentPage: (state) => state.form.currentPage,
				totalPages: (state) => state.form.totalPages,
			}),
			...mapState("form", ["items"]),
			filteredItems() {
				return this.items.filter(
					(item) =>
						item.scores >= this.scoreFilter || item.reviews >= this.reviewFilter
				);
			},
		},
		data() {
			return {
				scoreFilter: [],
				reviewFilter: [],
				min: "",
				max: "",
			};
		},
		mounted() {
			if (this.items.length == 0) {
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					capacity: this.$route.query.capacity,
					scores: this.scoreFilter,
					reviews: this.reviewFilter,
				};

				this.fetchProperties(queryParameters);
				this.$router.push({ name: "hotel-search", query: queryParameters });
			}
		},
		methods: {
			...mapActions("form", ["fetchProperties"]),
			applyFilters() {
				this.$store.commit("form/SET_SCORE_FILTER", this.scoreFilter);
				this.$store.commit("form/SET_REVIEW_FILTER", this.reviewFilter);
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					capacity: this.$route.query.capacity,
					scores: this.scoreFilter,
					reviews: this.reviewFilter,
				};

				this.fetchProperties(queryParameters);
				this.$router.push({ name: "hotel-search", query: queryParameters });
			},
			applyPriceFilters() {
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					capacity: this.$route.query.capacity,
					min_price: this.min,
					max_price: this.max,
				};

				this.fetchProperties(queryParameters);
				this.$router.push({ name: "hotel-search", query: queryParameters });
			},
			validateInput() {
				if (this.min <= 0 || isNaN(this.min)) {
					this.min = "";
				}
				if (this.max <= 0 || isNaN(this.max)) {
					this.max = "";
				}
			},
			next(page) {
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					capacity: this.$route.query.capacity,
					page: page,
					scores: this.$store.state.scoreFilter,
					reviews: this.$store.state.reviewFilter,
				};

				this.fetchProperties(queryParameters);
				this.$router.push({ name: "hotel-search", query: queryParameters });
			},
			formatNumber(number) {
				return new Intl.NumberFormat("es-ES", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(number);
			},
			prev(page) {
				let queryParameters = {
					start_date: this.$route.query.start_date,
					end_date: this.$route.query.end_date,
					capacity: this.$route.query.capacity,
					page: page,
					scores: this.$store.state.scoreFilter,
					reviews: this.$store.state.reviewFilter,
				};

				this.fetchProperties(queryParameters, page);
				this.$router.push({ name: "hotel-search", query: queryParameters });
			},
		},
	};
</script>
