import axios from "axios";
import { API_BASE_URL, PROPERTIES_API } from "@/api-config";

const state = {
	items: [],
	// start_date: "",
	// end_date: "",
	capacity: 0,
	// city_id: 0,
	// state_id: 0,
	// country_id: 0,
	results: {
		cities: [],
		states: [],
		countries: [],
		allOptions: [],
	},
	currentPage: 1,
	totalPages: 1,
	paginationLinks: {
		first: null,
		last: null,
		prev: null,
		next: null,
		pages: [],
	},
	number: 0,
	error: {
		status: false,
		message: "",
	},
	scoreFilter: 0,
	reviewFilter: 0,
};

const mutations = {
	SET_CAPACITY(state, capacity) {
		state.capacity = capacity;
	},
	SET_ERROR(state, error) {
		state.error = error;
	},
	SET_ITEMS(state, items) {
		state.items = items.items;
		state.paginationLinks = items.paginationLinks;
		state.totalPages = items.totalPages;
		state.currentPage = items.currentPage;
	},
	SET_SCORE_FILTER(state, scores) {
		state.scoreFilter = scores;
	},
	SET_REVIEW_FILTER(state, reviews) {
		state.reviewFilter = reviews;
	},
};

const actions = {
	updateCapacity({ commit }, capacity) {
		commit("SET_CAPACITY", capacity);
	},
	updateError({ commit }, error) {
		commit("SET_ERROR", error);
	},

	async fetchProperties({ commit, state }, queryParams) {
		let params = {
			start_date: queryParams.start_date,
			end_date: queryParams.end_date,
			capacity: queryParams.capacity,
			city_id: queryParams.city_id,
			state_id: queryParams.state_id,
			country_id: queryParams.country_id,
			page: queryParams.page == "undefined" ? 1 : queryParams.page,
			scores: state.scoreFilter,
			reviews: state.reviewFilter,
		};

		if (queryParams.min_price !== "undefined") {
			params.min_price = queryParams.min_price;
		}

		if (queryParams.max_price !== "undefined") {
			params.max_price = queryParams.max_price;
		}

		try {
			const response = await axios.get(`${API_BASE_URL}${PROPERTIES_API}`, {
				params: params,
			});

			response.data.meta.links.shift();
			response.data.meta.links.pop();

			commit("SET_ITEMS", {
				items: response.data.data,
				currentPage: response.data.meta.current_page,
				totalPages: response.data.meta.last_page,

				paginationLinks: {
					first: response.data.links.first,
					last: response.data.links.last,
					prev: response.data.links.prev,
					next: response.data.links.next,
					pages: response.data.meta.links,
				},
			});
			// commit("SET_SCORE_FILTER", {
			// 	scoreFilter: response.data.scores,
			// });
			// commit("SET_REVIEW_FILTER", {
			// 	reviewFilter: response.data.reviews,
			// });
		} catch (error) {
			commit("SET_ERROR", {
				status: true,
				message:
					error.response.status == 422
						? error.response.data.message
						: "Error en el servidor",
			});
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
