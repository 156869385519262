<template>
	<section id="testimonials_main_arae" class="section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="section_heading_center">
						<h2>{{ $t("testimonials") }}</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div
					v-for="(testimonialContent, index) in items"
					:key="index"
					class="col-lg-4 col-md-6"
				>
					<div class="all_review_box">
						<div class="all_review_date_area">
							<div class="all_review_date">
								<h5>{{ testimonialContent.date }}</h5>
							</div>
							<div class="all_review_star">
								<div class="review_star_all">
									<i
										class="fas fa-star"
										v-for="n in testimonialContent.scores"
										:key="n"
									></i>
								</div>
							</div>
						</div>
						<div class="all_review_text">
							<h4>
								{{ testimonialContent.customer }}
								<span>{{ testimonialContent.property }}</span>
							</h4>
							<p>"{{ testimonialContent.feedback }}"</p>
						</div>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="pagination_area">
						<ul class="pagination">
							<li class="page-item" :class="{ disabled: currentPage === 1 }">
								<a
									class="page-link"
									aria-label="Previous"
									@click="changePage(currentPage - 1)"
									:disabled="currentPage === 1"
								>
									<span aria-hidden="true">&laquo;</span>
									<span class="sr-only">Prev</span>
								</a>
							</li>
							<li
								class="page-item"
								:class="{ active: page.active }"
								v-for="page of paginationLinks.pages"
								:key="page.label"
							>
								<a class="page-link" @click="changePage(page.label)">{{
									page.label
								}}</a>
							</li>
							<li
								class="page-item"
								:class="{ disabled: currentPage === totalPages }"
							>
								<a
									class="page-link"
									aria-label="Next"
									@click="changePage(currentPage + 1)"
									:disabled="currentPage === totalPages"
								>
									<span aria-hidden="true">&raquo;</span>
									<span class="sr-only">Next</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	// import data from "../../data";
	import axios from "axios";
	import { API_BASE_URL, GET_TESTIMONIALS_API } from "@/api-config";
	export default {
		name: "Testimonials",
		data() {
			return {
				items: [],
				currentPage: 1,
				totalPages: 1,
				itemsPerPage: 6,
				paginationLinks: {
					first: null,
					last: null,
					prev: null,
					next: null,
					pages: [],
				},
				minScore: 4,
			};
		},

		mounted() {
			if (this.$route.params.id == undefined) {
				this.fetchTestimonials();
			} else {
				this.fetchTestimonialProperty();
			}
		},
		methods: {
			changePage(page) {
				if (this.$route.params.id == undefined) {
					this.fetchTestimonials(page);
				} else {
					this.fetchTestimonialProperty(page);
				}
			},
			fetchTestimonials(page) {
				axios
					.get(`${API_BASE_URL}${GET_TESTIMONIALS_API}`, {
						params: {
							min_scores: this.minScore,
							per_page: this.itemsPerPage,
							page: page,
						},
					})
					.then((response) => {
						this.items = response.data.data;
						this.currentPage = response.data.meta.current_page;
						this.totalPages = response.data.meta.last_page;

						response.data.meta.links.shift();
						response.data.meta.links.pop();

						this.paginationLinks = {
							first: response.data.links.first,
							last: response.data.links.last,
							prev: response.data.links.prev,
							next: response.data.links.next,
							pages: response.data.meta.links,
						};

						this.paginationLinks.pages;
					})
					.catch((error) => {
						console.error("Error fetching properties:", error);
					});
			},
			fetchTestimonialProperty(page = 1) {
				axios
					.get(
						`${API_BASE_URL}${GET_TESTIMONIALS_API}/${this.$route.params.id}`,
						{
							params: {
								per_page: this.itemsPerPage,
								page: page,
							},
						}
					)
					.then((response) => {
						this.items = response.data.data;
						this.currentPage = response.data.meta.current_page;
						this.totalPages = response.data.meta.last_page;

						response.data.meta.links.shift();
						response.data.meta.links.pop();

						this.paginationLinks = {
							first: response.data.links.first,
							last: response.data.links.last,
							prev: response.data.links.prev,
							next: response.data.links.next,
							pages: response.data.meta.links,
						};
					})
					.catch((error) => {
						console.error("Error fetching properties:", error);
					});
			},
		},
	};
</script>
