<template>
	<section id="tour_booking_submission" class="section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-8">
					<div class="tou_booking_form_Wrapper">
						<div class="tour_booking_form_box mb-4">
							<div class="booking_success_arae">
								<div class="booking_success_img">
									<img src="../../assets/img/icon/right.png" alt="img" />
								</div>
								<div class="booking_success_text">
									<h3>Su pedido fue enviado exitosamente</h3>
									<h6>
										Los datos de tu reserva han sido enviados a:
										tucorreo@mail.com
									</h6>
								</div>
							</div>
						</div>
						<div class="booking_tour_form">
							<h3 class="heading_theme">Su informacion</h3>
							<div class="tour_booking_form_box">
								<div class="your_info_arae">
									<ul>
										<li>
											<span class="name_first">Nombres:</span>
											<span class="last_name">John</span>
										</li>
										<li>
											<span class="name_first">Apellidos:</span>
											<span class="last_name">Doe</span>
										</li>
										<li>
											<span class="name_first">Correo electrónico:</span>
											<span class="last_name">tucorreo@mail.comm</span>
										</li>
										<li>
											<span class="name_first">Dirección:</span>
											<span class="last_name"
												>1901 Thornridge Cir. Shiloh, Hawaii 81063</span
											>
										</li>
										<li>
											<span class="name_first">Ciudad:</span>
											<span class="last_name">Thornridge</span>
										</li>
										<li>
											<span class="name_first">Estado:</span>
											<span class="last_name">Hawaii</span>
										</li>
										<li>
											<span class="name_first">Código Postal:</span>
											<span class="last_name">81063</span>
										</li>
										<li>
											<span class="name_first">País:</span>
											<span class="last_name">USA</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="tour_details_right_sidebar_wrapper">
						<div class="tour_detail_right_sidebar">
							<div class="tour_details_right_boxed">
								<div class="tour_details_right_box_heading">
									<h3>Detalles de la reserva</h3>
								</div>
								<div class="tour_booking_amount_area">
									<ul>
										<li>ID reserva: <span>#RB5783GH</span></li>
										<li>Fecha reserva: <span>10 Feb 2022</span></li>
										<li>Método de Pago: <span>Transferencia Bancaria</span></li>
										<li>Estatus de la reserva: <span>Aprobada</span></li>
									</ul>
									<ul>
										<li>Estadia por 1 noche<span>$400.000,00</span></li>
										<li>Tarifa de Limpieza <span>$156.028,00</span></li>
										<li>Gastos Administrativos<span>23.316,00</span></li>
									</ul>
									<div class="tour_bokking_subtotal_area">
										<div class="tour_bokking_subtotal_area">
											<h6>Subtotal <span>$579.344,00</span></h6>
										</div>
									</div>
									<!-- <div class="coupon_add_area">
										<h6>
											<span class="remove_coupon_tour">Remove</span> Coupon code
											(OFF 5000)
											<span>$5,000.00</span>
										</h6>
									</div> -->
									<div class="total_subtotal_booking">
										<h6>
											Monto total
											<span>$579.344,00</span>
										</h6>
										<sub>Impuestos incluídos</sub>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		name: "TourBooking",
	};
</script>
