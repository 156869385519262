<template>
	<section id="common_banner">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="common_bannner_text">
						<!-- <h2>Resultado de la búsqueda de hoteles</h2>
						<ul>
							<li><router-link to="/">Inicio</router-link></li>
							<li>
								<span><i class="fas fa-circle"></i></span> Hotel
							</li>
						</ul> -->
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		name: "Banner",
	};
</script>
