<template>
	<section id="explore_area" class="section_padding_top">
		<div class="container">
			<!-- Section Heading -->
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="section_heading_center">
						<h2>{{ $t("featured") }}</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="theme_nav_tab">
						<nav class="theme_nav_tab_item">
							<div class="nav nav-tabs">
								<button
									v-for="(category, index) in categories"
									:key="index"
									:class="{
										'nav-link': true,
										active: selectedCategoryId == category.id,
									}"
									type="button"
									@click="selectCategory(category)"
									:style="index > 9 ? { margin: '10px' } : { margin: '5px' }"
								>
									{{ $t(category.text) }}
								</button>
							</div>
						</nav>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="tab-content" id="nav-tabContent">
						<div class="row">
							<div
								class="promotional_tour_slider owl-theme owl-carousel dot_style"
							>
								<swiper
									:slides-per-view="4"
									:space-between="20"
									:pagination="{ clickable: true }"
								>
									<swiper-slide
										v-for="(item, index) in selectedCategory"
										:key="index"
									>
										<div
											class="tab-pane fade show active"
											id="nav-hotels"
											role="tabpanel"
											aria-labelledby="nav-hotels-tab"
										>
											<div class="theme_common_box_two img_hover">
												<div class="theme_two_box_img">
													<router-link
														:to="{
															name: `hotel-details`,
															params: { id: item.id },
															query: {
																start_date: defaultStartDate,
																end_date: defaultEndDate,
																capacity: 1,
															},
														}"
													>
														<img :src="item.imagen" alt="img" />
													</router-link>
												</div>
												<div class="theme_two_box_content">
													<h4>
														<router-link
															:to="{
																name: `hotel-details`,
																params: { id: item.id },
																query: {
																	start_date: defaultStartDate,
																	end_date: defaultEndDate,
																	capacity: 1,
																},
															}"
															>{{ item.name }}</router-link
														>
													</h4>
													<p>
														<span class="review_rating">{{ item.visits }}</span>
														<span class="review_count">{{
															item.punctuation
														}}</span>
													</p>
												</div>
											</div>
										</div>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import "swiper/swiper-bundle.css";
	import axios from "axios";
	import {
		API_BASE_URL,
		OUTSTANDING_PROPERTIES_API,
		CATEGORIES_API,
	} from "@/api-config";

	export default {
		name: "OutstandingProperties",

		components: {
			Swiper,
			SwiperSlide,
		},

		data() {
			const currentDate = new Date();
			const endDate = new Date(currentDate);
			endDate.setDate(currentDate.getDate() + 30);

			return {
				items: [],
				categories: [],
				selectedCategoryId: null,
				defaultStartDate: currentDate.toISOString().slice(0, 10),
				defaultEndDate: endDate.toISOString().slice(0, 10),
			};
		},
		computed: {
			selectedCategory() {
				return this.items.filter(
					(category) => category.category_id == this.selectedCategoryId
				);
			},
		},

		mounted() {
			this.fetchCategories();
			this.fetchPropertyOutstanding();
		},
		methods: {
			selectCategory(category) {
				this.selectedCategoryId = category.id;
			},

			fetchCategories() {
				axios
					.get(`${API_BASE_URL}${CATEGORIES_API}`)
					.then((response) => {
						this.categories = response.data.results;
						this.selectedCategoryId =
							this.categories.length > 0 ? this.categories[0].id : null;
					})
					.catch((error) => {
						console.error("Error fetching categories:", error);
					});
			},
			fetchPropertyOutstanding() {
				axios
					.get(`${API_BASE_URL}${OUTSTANDING_PROPERTIES_API}`)
					.then((response) => {
						this.items = response.data.data;
					})
					.catch((error) => {
						console.error("Error fetching properties:", error);
					});
			},
		},
	};
</script>
