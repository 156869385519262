<template>
	<!-- Banner Area -->
	<Banner />

	<!-- Form Area -->
	<Form />

	<!-- imagination Area -->
	<Imagination />

	<!-- Top destinations -->
	<TopDestinations />

	<!--Promotional Tours Area -->
	<RecommendedProperties />

	<!-- Explore our hot deals -->
	<OutstandingProperties />

	<!-- Offer Area
	<Offer /> -->

	<!-- Destinations Area -->
	<!-- <Destinations /> -->

	<!-- News Area -->
	<!-- <News /> -->

	<!-- Our partners Area -->
	<!-- <Partners /> -->

	<!-- Testimonials Area -->
	<Testimonials />

	<!-- Cta Area 
	<Cta />-->
</template>

<script>
	// @ is an alias to /src
	import Banner from "@/components/home/Banner.vue";
	import Form from "@/components/home/Form.vue";
	import Imagination from "@/components/home/Imagination.vue";
	import TopDestinations from "@/components/home/TopDestinations.vue";
	import RecommendedProperties from "@/components/home/RecommendedProperties.vue";
	import OutstandingProperties from "@/components/home/OutstandingProperties.vue";
	// import Offer from "@/components/home/Offer.vue";
	// import Destinations from "@/components/home/Destinations.vue";
	// import News from "@/components/home/News.vue";
	// import Partners from "@/components/home/Partners.vue";
	//import Cta from "@/components/home/Cta.vue";
	import Testimonials from "@/components/pages/Testimonials.vue";

	export default {
		name: "HomeView",
		components: {
			Banner,
			Form,
			Imagination,
			TopDestinations,
			RecommendedProperties,
			OutstandingProperties,
			// Offer,
			// Destinations,
			// News,
			// Partners,
			// Cta,
			Testimonials,
		},
	};
</script>
