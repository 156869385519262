<template>
	<section id="contact_main_arae" class="section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="section_heading_center">
						<h2>{{ $t("contacts") }}</h2>
					</div>
				</div>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-lg-5">
						<div class="tour_details_boxed">
							<div class="map_area">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
								></iframe>
							</div>
						</div>
					</div>
					<div class="col-lg-7">
						<div class="news_area_top_right">
							<br />
							<h3 style="text-align: center">
								{{ $t("contact_us_to_help_you") }}
							</h3>
							<p>{{ $t("text_contact_us") }}</p>
							<h3>+57 604 256 3458</h3>
							<h3>+57 315 256 7889</h3>
						</div>
					</div>
				</div>
			</div>

			<div
				ref="modal"
				class="modal fade"
				:class="{ show: active, 'd-block': active }"
				tabindex="-1"
				role="dialog"
			>
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content">
						<div class="modal-body map_modal_content">
							<div class="btn_modal_closed">
								<button
									type="button"
									data-bs-dismiss="modal"
									aria-label="Close"
									@click="modalToggle"
								>
									<i class="fas fa-times"></i>
								</button>
							</div>
							<div class="map_area">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="contact_main_form_area">
				<div class="row">
					<div class="col-lg-8 offset-lg-2">
						<div class="section_heading_center">
							<h2>{{ $t("connect_with_an_advisor") }}</h2>
						</div>
						<div class="contact_form">
							<form action="!#" id="contact_form_content">
								<div class="row">
									<div class="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control bg_input"
												:placeholder="$t('name')"
												v-model="form.name"
											/>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control bg_input"
												:placeholder="$t('email')"
												v-model="form.email"
											/>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control bg_input"
												:placeholder="$t('phone')"
												v-model="form.phone"
											/>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control bg_input"
												:placeholder="$t('company')"
												v-model="form.company"
											/>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="form-group">
											<v-select
												class="form-control bg_input"
												v-model="countrySelected"
												label="text"
												:options="filteredCountries"
												:filterable="true"
												:placeholder="$t('country')"
											></v-select>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="form-group">
											<v-select
												class="form-control bg_input"
												v-model="citySelected"
												label="text"
												:options="filteredCities"
												:filterable="true"
												:placeholder="$t('city')"
											>
											</v-select>
										</div>
									</div>
									<div class="col-lg-12">
										<div class="form-group">
											<textarea
												class="form-control bg_input"
												rows="5"
												:placeholder="$t('how_can_we_help_you')"
												v-model="form.message"
											></textarea>
										</div>
									</div>
									<div class="col-lg-12 text-center">
										<div class="form-group">
											<button
												type="button"
												class="btn btn_theme btn_md"
												@click="postContact"
											>
												{{ $t("send_message") }}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import Swal from "sweetalert2";
	import {
		API_BASE_URL,
		COUNTRY_API,
		CITY_API,
		CONTACT_API,
	} from "@/api-config";
	import axios from "axios";

	export default {
		name: "ContactArea",

		data() {
			return {
				active: false,
				countries: [],
				cities: [],
				countrySelected: null,
				citySelected: "",
				searchCountry: "",
				searchCity: "",
				form: {
					name: "",
					email: "",
					phone: "",
					company: "",
					country_id: "",
					city_id: "",
					message: "",
				},
			};
		},
		mounted() {
			this.getCountries();
		},
		watch: {
			countrySelected() {
				this.getCities();
			},
		},
		computed: {
			filteredCountries() {
				if (this.searchCountry) {
					return this.countries.filter((country) =>
						country.text
							.toLowerCase()
							.includes(this.searchCountry.toLowerCase())
					);
				}
				return this.countries;
			},
			filteredCities() {
				if (this.searchCity) {
					return this.cities.filter((city) =>
						city.text.toLowerCase().includes(this.searchCity.toLowerCase())
					);
				}
				return this.cities;
			},
		},
		methods: {
			showAlert(message, type) {
				Swal.fire({
					icon: type,
					title: type === "success" ? this.$t("success") : "Error",
					text: message,
				});
			},
			modalToggle() {
				const body = document.querySelector("body");
				this.active = !this.active;
				this.active
					? body.classList.add("modal-open")
					: body.classList.remove("modal-open");
			},
			getCountries() {
				axios
					.get(`${API_BASE_URL}${COUNTRY_API}`)
					.then((response) => {
						this.countries = response.data.results;
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getCities() {
				if (this.countrySelected) {
					this.form.country_id = this.countrySelected.id;
					const selectedCountry = this.countrySelected;
					axios
						.get(`${API_BASE_URL}${CITY_API}`, {
							params: { model: selectedCountry.id },
						})
						.then((response) => {
							this.cities = response.data.results;

							if (this.cities.length > 0) {
								this.citySelected = this.cities[0].text;
							} else {
								this.citySelected = null;
							}
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					this.cities = [];
					this.citySelected = [];
				}
			},
			postContact() {
				this.form.city_id = this.citySelected.id;
				axios
					.post(`${API_BASE_URL}${CONTACT_API}`, this.form)
					.then((response) => {
						if (response.data && response.data.message) {
							const successMessage = response.data.message;
							this.showAlert(successMessage, "success");
						}
						this.form = {
							name: "",
							email: "",
							phone: "",
							company: "",
							country_id: "",
							city_id: "",
							message: "",
						};
						this.countrySelected = null;
						this.citySelected = null;
					})
					.catch((error) => {
						if (
							error.response &&
							error.response.data &&
							error.response.data.message
						) {
							const errorMessage = error.response.data.message;
							this.showAlert(errorMessage, "error");
						} else {
							this.showAlert("Error desconocido");
						}
					});
			},
		},
	};
</script>
