<template>
	<div class="col-lg-2 col-md-6 col-sm-12 col-12">
		<div class="flight_Search_boxed dropdown_passenger_area">
			<p>{{ $t("guests") }}</p>
			<div class="dropdown">
				<button
					class="dropdown-toggle final-count"
					data-toggle="dropdown"
					type="button"
					id="dropdownMenuButton1"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					{{ number }} {{ $t("guests") }}
				</button>

				<div
					class="dropdown-menu dropdown_passenger_info"
					aria-labelledby="dropdownMenuButton1"
				>
					<div class="traveller-calulate-persons">
						<div class="passengers">
							<h6>{{ $t("guests") }}</h6>
							<div class="passengers-types">
								<div class="passengers-type">
									<div class="text">
										<span class="count pcount">{{ pnumber }}</span>
										<div class="type-label">
											<p>{{ $t("adults") }}</p>
											<span>{{ $t("more_than_12_years") }}</span>
										</div>
									</div>
									<div class="button-set">
										<button
											type="button"
											class="btn-add"
											@click.stop="increment"
										>
											<i class="fas fa-plus"></i>
										</button>
										<button
											type="button"
											class="btn-subtract"
											@click.stop="decrement"
											:disabled="pnumber === 1"
										>
											<i class="fas fa-minus"></i>
										</button>
									</div>
								</div>
								<div class="passengers-type">
									<div class="text">
										<span class="count ccount">{{ cnumber }}</span>
										<div class="type-label">
											<p class="fz14 mb-xs-0">{{ $t("children") }}</p>
											<span>{{ $t("less_than_12_years") }}</span>
										</div>
									</div>
									<div class="button-set">
										<button
											type="button"
											class="btn-add-c"
											@click.stop="incrementc"
										>
											<i class="fas fa-plus"></i>
										</button>
										<button
											type="button"
											class="btn-subtract-c"
											@click.stop="decrementc"
										>
											<i class="fas fa-minus"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapActions } from "vuex";
	export default {
		name: "CountDropdownHotel",
		data() {
			return {
				number: 0,
				pnumber: 0,
				cnumber: 0,
			};
		},
		mounted() {
			this.number = this.$route.query.capacity ? this.$route.query.capacity : 1;
			this.pnumber = this.$route.query.capacity
				? this.$route.query.capacity
				: 1;
		},
		watch: {
			number(val) {
				this.updateCapacity(val);
			},
		},
		methods: {
			...mapActions("form", ["updateCapacity"]),

			increment() {
				this.number++;
				this.pnumber++;
			},

			decrement() {
				if (this.number === 0) return;
				this.number -= 1;

				if (this.pnumber === 0) return;

				this.pnumber -= 1;
			},

			incrementc() {
				this.number++;
				this.cnumber++;
			},

			decrementc() {
				if (this.number === 0) return;
				this.number -= 1;

				if (this.cnumber === 0) return;

				this.cnumber -= 1;
			},
		},
	};
</script>
