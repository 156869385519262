<template>
	<div
		class="promotional_tour_slider owl-theme owl-carousel dot_style"
		style="position: relative; z-index: 1"
	>
		<swiper
			:slides-per-view="1"
			:space-between="1"
			:pagination="{ clickable: true }"
			:autoplay="{ delay: 4000 }"
		>
			<swiper-slide v-for="banner in banners" :key="banner.id">
				<section
					id="home_one_banner"
					:style="'background-image: url(' + banner.image + ')'"
				>
					<div class="container">
						<div class="row align-items-center">
							<div class="col-lg-12">
								<div class="banner_one_text"></div>
							</div>
						</div>
					</div>
				</section>
			</swiper-slide>
		</swiper>
	</div>
</template>
<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import "swiper/swiper-bundle.css";
	import axios from "axios";
	import { API_BASE_URL, BANNERS_API } from "@/api-config";

	export default {
		name: "Banner",

		components: {
			Swiper,
			SwiperSlide,
		},

		data() {
			return {
				swiper: null,
				banners: [],
				typeBanner: 2,
			};
		},

		mounted() {
			this.fetchBanners();
		},

		methods: {
			fetchBanners() {
				axios
					.get(`${API_BASE_URL}${BANNERS_API}${this.typeBanner}`)
					.then((response) => {
						this.banners = response.data.data;
					})
					.catch((error) => {
						console.error("Error fetching banners:", error);
					});
			},
		},
	};
</script>
