<template>
	<section id="tour_booking_submission" class="section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-8">
					<div class="tou_booking_form_Wrapper">
						<div class="booking_tour_form">
							<h3 class="heading_theme">Envío de reserva</h3>
							<div class="tour_booking_form_box">
								<form action="!#" id="tour_bookking_form_item">
									<div class="row">
										<div class="col-lg-6">
											<div class="form-group">
												<input
													type="text"
													class="form-control bg_input"
													placeholder="Nombres*"
												/>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<input
													type="text"
													class="form-control bg_input"
													placeholder="Apellidos*"
												/>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<input
													type="text"
													class="form-control bg_input"
													placeholder="Correo electrónico*"
												/>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<input
													type="text"
													class="form-control bg_input"
													placeholder="Numero de teléfono móvil*"
												/>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<input
													type="text"
													class="form-control bg_input"
													placeholder="Dirección"
												/>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<input
													type="text"
													class="form-control bg_input"
													placeholder="Número de suite o habitación (opcional)"
												/>
											</div>
										</div>
										<!-- <div class="col-lg-6">
											<div class="form-group">
												<select class="form-control form-select bg_input">
													<option value="1">Khulna</option>
													<option value="1">New York</option>
													<option value="1">Barisal</option>
													<option value="1">Nator</option>
													<option value="1">Joybangla</option>
												</select>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<select class="form-control form-select bg_input">
													<option value="1">State</option>
													<option value="1">New York</option>
													<option value="1">Barisal</option>
													<option value="1">Nator</option>
													<option value="1">Joybangla</option>
												</select>
											</div>
										</div>
										<div class="col-lg-6">
											<div class="form-group">
												<select class="form-control form-select bg_input">
													<option value="1">Country</option>
													<option value="1">New York</option>
													<option value="1">Barisal</option>
													<option value="1">Nator</option>
													<option value="1">Joybangla</option>
												</select>
											</div>
										</div> -->
									</div>
								</form>
							</div>
						</div>
						<div class="booking_tour_form">
							<h3 class="heading_theme">Método de Pago</h3>
							<div class="tour_booking_form_box">
								<div class="booking_payment_boxed">
									<form action="!#" id="payment_checked">
										<div class="form-check">
											<input
												class="form-check-input"
												type="radio"
												name="flexRadioDefault"
												id="flexRadioDefault1"
												value="red"
											/>
											<label class="form-check-label" for="flexRadioDefault1">
												PSE
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="radio"
												name="flexRadioDefault"
												id="flexRadioDefault2"
												value="green"
											/>
											<label class="form-check-label" for="flexRadioDefault2">
												Mastercard
											</label>
										</div>
										<div class="form-check">
											<input
												class="form-check-input"
												type="radio"
												name="flexRadioDefault"
												id="flexRadioDefault3"
												value="black"
											/>
											<label class="form-check-label" for="flexRadioDefault3">
												Visa
											</label>
										</div>
										<div class="payment_filed_wrapper">
											<div class="payment_card payment_toggle red">
												<div class="row">
													<div class="col-lg-6">
														<div class="form-group">
															<input
																type="text"
																class="form-control bg_input"
																placeholder="Número tarjeta"
															/>
														</div>
													</div>
													<div class="col-lg-6">
														<div class="form-group">
															<input
																type="text"
																class="form-control bg_input"
																placeholder="Titular de tarjeta"
															/>
														</div>
													</div>
													<div class="col-lg-6">
														<div class="form-group">
															<input
																type="text"
																class="form-control bg_input"
																placeholder="Fecha de vencimiento"
															/>
														</div>
													</div>
													<div class="col-lg-6">
														<div class="form-group">
															<input
																type="text"
																class="form-control bg_input"
																placeholder="Código de Seguridad"
															/>
														</div>
													</div>
												</div>
											</div>
											<div class="paypal_payment payment_toggle green">
												<div class="row">
													<div class="col-lg-6">
														<div class="form-group">
															<input
																type="text"
																class="form-control bg_input"
																placeholder="Correo electrónico"
															/>
														</div>
													</div>
												</div>
											</div>
											<div class="payoneer_payment payment_toggle black">
												<div class="row">
													<div class="col-lg-6">
														<div class="form-group">
															<input
																type="text"
																class="form-control bg_input"
																placeholder="Correo electrónico"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div class="booking_tour_form_submit">
							<div class="form-check write_spical_check">
								<input
									class="form-check-input"
									type="checkbox"
									value=""
									id="flexCheckDefaultf1"
								/>
								<label class="form-check-label" for="flexCheckDefaultf1">
									<span class="main_spical_check">
										<span>
											<router-link to="/terms-service"
												>Leo y acepto todos los términos y
												condiciones</router-link
											></span
										>
									</span>
								</label>
							</div>
							<router-link
								to="/booking-confirmation"
								class="btn btn_theme btn_md"
								>Enviar</router-link
							>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="tour_details_right_sidebar_wrapper">
						<div class="tour_detail_right_sidebar">
							<div class="tour_details_right_boxed">
								<div class="tour_details_right_box_heading">
									<h3>Habitación doble de lujo</h3>
								</div>
								<div class="valid_date_area">
									<div class="valid_date_area_one">
										<h5>Válido desde</h5>
										<p>01 Dic 2023</p>
									</div>
									<div class="valid_date_area_one">
										<h5>Valido hasta</h5>
										<p>2 Dic 2023</p>
									</div>
								</div>
								<div class="tour_package_details_bar_list">
									<h5>Comodidades de la habitacion</h5>
									<ul>
										<li>
											<i class="fas fa-circle"></i>Desayuno buffet según el
											Itinerario
										</li>
										<li>
											<i class="fas fa-circle"></i>Visita a lugares Históricos y
											Culturales
										</li>
										<li>
											<i class="fas fa-circle"></i>Tour de Paseo por la Ciudad
										</li>
										<li>
											<i class="fas fa-circle"></i>Todos los impuestos de peaje,
											estacionamiento, combustible, y asignaciones para
											conductores
										</li>
										<li>
											<i class="fas fa-circle"></i>Vehículo cómodo e higiénico.
										</li>
									</ul>
								</div>
								<div class="tour_package_details_bar_price">
									<h5>Precio</h5>
									<div class="tour_package_bar_price">
										<h3>$400.000 <sub>/Por noche</sub></h3>
									</div>
								</div>
							</div>
						</div>
						<div class="tour_detail_right_sidebar">
							<div class="tour_details_right_boxed">
								<div class="tour_details_right_box_heading">
									<h3>Fecha de viaje</h3>
								</div>
								<div class="edit_date_form">
									<div class="form-group">
										<label for="dates">Editar Fecha</label>
										<input
											type="date"
											id="dates"
											value="2022-05-05"
											class="form-control"
										/>
									</div>
								</div>
								<div class="tour_package_details_bar_list">
									<h5>Huéspedes</h5>
									<div class="select_person_item">
										<div class="select_person_left">
											<h6>Adultos</h6>
											<p>12+ años</p>
										</div>
										<div class="select_person_right">
											<h6>01</h6>
										</div>
									</div>

									<div class="select_person_item">
										<div class="select_person_left">
											<h6>Niños</h6>
											<p>0 - 12 años</p>
										</div>
										<div class="select_person_right">
											<h6>01</h6>
										</div>
									</div>
									<!-- <div class="select_person_item">
										<div class="select_person_left">
											<h6>Infant</h6>
											<p>Below 2 years</p>
										</div>
										<div class="select_person_right">
											<h6>01</h6>
										</div>
									</div> -->
								</div>
								<div class="edit_person">
									<p>Editar huespedes</p>
								</div>
							</div>
						</div>
						<!-- <div class="tour_detail_right_sidebar">
							<div class="tour_details_right_boxed">
								<div class="tour_details_right_box_heading">
									<h3>Coupon code</h3>
								</div>
								<div class="coupon_code_area_booking">
									<form action="#!">
										<div class="form-group">
											<input
												type="text"
												class="form-control bg_input"
												placeholder="Enter coupon code"
											/>
										</div>
										<div class="coupon_code_submit">
											<button class="btn btn_theme btn_md">
												Apply voucher
											</button>
										</div>
									</form>
								</div>
							</div>
						</div> -->
						<div class="tour_detail_right_sidebar">
							<div class="tour_details_right_boxed">
								<div class="tour_details_right_box_heading">
									<h3>Importe de la Reserva</h3>
								</div>

								<div class="tour_booking_amount_area">
									<ul>
										<li>Estadia por 1 noche<span>$400.000,00</span></li>
										<li>Tarifa de Limpieza <span>$156.028,00</span></li>
										<li>Gastos Administrativos<span>23.316,00</span></li>
									</ul>
									<div class="tour_bokking_subtotal_area">
										<h6>Subtotal <span>$579.344,00</span></h6>
									</div>
									<!-- <div class="coupon_add_area">
										<h6>
											<span class="remove_coupon_tour">Quitar</span> Código Cupon
											(OFF 5000)
											<span>$5,000.00</span>
										</h6>
									</div> -->
									<div class="total_subtotal_booking">
										<h6>
											Monto total
											<span>$579.344,00</span>
										</h6>
										<sub>Impuestos incluídos</sub>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	export default {
		name: "TourBookingSubmission",
	};
</script>
