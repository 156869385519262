<template>
	<section id="promotional_tours" class="section_padding_top">
		<div class="container">
			<!-- Section Heading -->
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="section_heading_center">
						<h2>{{ $t("recommended") }}</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="promotional_tour_slider owl-theme owl-carousel dot_style">
						<swiper
							:slides-per-view="4"
							:space-between="20"
							:pagination="{ clickable: true }"
						>
							<swiper-slide v-for="(hotelInfo, index) in items" :key="index">
								<div class="theme_common_box_two img_hover">
									<div class="theme_two_box_img">
										<router-link
											:to="{
												name: `hotel-details`,
												params: { id: hotelInfo.id },
												query: {
													start_date: defaultStartDate,
													end_date: defaultEndDate,
													capacity: 1,
												},
											}"
										>
											<img :src="hotelInfo.imagen" alt="img"
										/></router-link>
										<p>
											<i class="fas fa-map-marker-alt"></i>{{ hotelInfo.city }}
										</p>
									</div>
									<div class="theme_two_box_content">
										<h4>
											<router-link to="/hotel-details">{{
												hotelInfo.name
											}}</router-link>
										</h4>
										<p>
											<!-- <span class="review_rating">4.8/5 Excellent</span>
											<span class="review_count">(1214 reviewes)</span> -->
										</p>
										<!-- <h3>$99.00 <span>Price starts from</span></h3> -->
									</div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import "swiper/swiper-bundle.css";
	import axios from "axios";
	import { API_BASE_URL, RECOMMENDED_PROPERTIES_API } from "@/api-config";

	export default {
		name: "RecommendedProperties",

		components: {
			Swiper,
			SwiperSlide,
		},

		data() {
			const currentDate = new Date();
			const endDate = new Date(currentDate);
			endDate.setDate(currentDate.getDate() + 30);

			return {
				swiper: null,
				items: [],
				defaultStartDate: currentDate.toISOString().slice(0, 10),
				defaultEndDate: endDate.toISOString().slice(0, 10),
			};
		},
		mounted() {
			this.fetchPropertyRecomended();
		},
		methods: {
			fetchPropertyRecomended() {
				axios
					.get(`${API_BASE_URL}${RECOMMENDED_PROPERTIES_API}`)
					.then((response) => {
						this.items = response.data.data;
					})
					.catch((error) => {
						console.error("Error fetching properties:", error);
					});
			},
		},
	};
</script>
