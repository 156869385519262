<template>
	<!-- Common Banner Area -->
	<Banner />

	<!-- Form Area -->
	<KeepAlive>
		<Form
			:props_destination="destination"
			:props_start_date="start_date"
			:props_end_date="end_date"
			:props_capacity="capacity"
			:props_city_id="city_id"
			:props_state_id="state_id"
			:props_country_id="country_id"
			:props_scores="scores"
			:props_reviews="reviews"
		/>
	</KeepAlive>

	<!-- Hotel Search Areas -->
	<SearchResult />

	<!-- Cta Area 
    <Cta />-->
</template>

<script>
	// @ is an alias to /src
	import Banner from "@/components/hotel/Banner.vue";
	import Form from "@/components/home/Form.vue";
	import SearchResult from "@/components/hotel/SearchResult.vue";
	// import Cta from "@/components/home/Cta.vue";
	import axios from "axios";
	import { API_BASE_URL, PROPERTIES_API } from "@/api-config";

	export default {
		name: "HotelSearchView",
		components: {
			Banner,
			Form,
			SearchResult,
		},

		data() {
			return {
				items: [],
				destination: this.$route.query.destination,
				start_date: this.$route.query.start_date,
				end_date: this.$route.query.end_date,
				city_id: this.$route.query.city_id,
				state_id: this.$route.query.state_id,
				country_id: this.$route.query.country_id,
				capacity: this.$route.query.capacity,
				scores: this.$route.query.scores,
				reviews: this.$route.query.reviews,
			};
		},
		methods: {
			fetchProperties() {
				axios
					.get(`${API_BASE_URL}${PROPERTIES_API}`, {
						params: {
							start_date: this.start_date,
							end_date: this.end_date,
							capacity: this.capacity,
							city_id: this.city_id,
							state_id: this.state_id,
							country_id: this.country_id,
							scores: this.scores,
							reviews: this.reviews,
						},
					})
					.then((response) => {
						this.items = response.data.data;
					})
					.catch((error) => {
						console.error("Error fetching properties:", error);
					});
			},
		},
	};
</script>
