<template>
	<section id="tour_details_main" class="section_padding">
		<div class="container">
			<div class="col-lg-12 col-md-12 col-sm-12 col-12">
				<div class="section_heading_center">
					<h2>{{ $t("property_details") }}</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-10 offset-lg-1">
					<div class="tour_details_leftside_wrapper">
						<div class="tour_details_heading_wrapper">
							<div class="tour_details_top_heading">
								<h2>{{ property.data.name }}</h2>
								<!-- <h5><i class="fas fa-map-marker-alt"></i> Los angeles</h5> -->
							</div>
							<!-- <div class="tour_details_top_heading_right">
                                <h4>Excellent</h4>
                                <h6>4.8/5</h6>
                                <p>(1214 reviewes)</p>
                            </div> -->
						</div>
						<div class="tour_details_img_wrapper">
							<div class="image__gallery">
								<div class="gallery">
									<div class="gallery__slideshow">
										<div class="gallery__slides">
											<div class="gallery__slide">
												<img
													class="gallery__img"
													:src="slide.img"
													alt=""
													v-for="(slide, index) in gallerySlides"
													:key="index"
													:style="
														slideIndex === index
															? 'display:block;'
															: 'display:none;'
													"
												/>
											</div>
										</div>
										<a @click.prevent="move(-1)" class="gallery__prev"
											>&#10095;</a
										>
										<a @click.prevent="move(1)" class="gallery__next"
											>&#10094;</a
										>
									</div>

									<div class="gallery__content">
										<div class="gallery__items">
											<div
												class="gallery__item"
												:class="{
													'gallery__item--is-acitve': slideIndex === index,
												}"
												v-for="(slide, index) in gallerySlides"
												:key="`item-${index}`"
											>
												<img
													:src="slide.img"
													@click="currentSlide(index)"
													class="gallery__item-img"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tour_details_boxed">
							<h3 class="heading_theme">{{ $t("general_description") }}</h3>
							<div class="tour_details_boxed_inner">
								{{ property.data.description }}
							</div>
							<div class="tour_package_details_bar_list">
								<h5>{{ $t("property_amenities") }}</h5>
								<ul>
									<li v-for="amenity in amenities" :key="amenity.id">
										<i class="fas fa-circle"></i>{{ amenity.name }}
									</li>
								</ul>
							</div>
						</div>
						<div class="tour_details_boxed">
							<h3 class="heading_theme">
								{{ $t("select_the_dates_of_your_stay") }}
							</h3>
							<div class="room_select_area">
								<ul class="nav nav-tabs" id="myTab" role="tablist">
									<li class="nav-item" role="presentation">
										<h4>{{ $t("book_with_us") }}</h4>
										<!-- <button
											class="nav-link"
											id="home-tab"
											data-bs-toggle="tab"
											data-bs-target="#home"
											type="button"
											role="tab"
											aria-controls="home"
											aria-selected="true"
										>
											Reserva
										</button> -->
									</li>
									<!-- <li class="nav-item" role="presentation">
										<button
											class="nav-link"
											id="profile-tab"
											data-bs-toggle="tab"
											data-bs-target="#profile"
											type="button"
											role="tab"
											aria-controls="profile"
											aria-selected="false"
										>
											Enquiry
										</button>
									</li> -->
								</ul>
								<div class="tab-content" id="myTabContent">
									<div
										class="tab-pane fade show active"
										id="home"
										role="tabpanel"
										aria-labelledby="home-tab"
									>
										<div class="room_booking_area">
											<div class="tour_search_form">
												<form @submit.prevent="fetchProperty()">
													<div class="row">
														<div class="col-lg-8 col-md-6 col-sm-12 col-12">
															<div class="form_search_date">
																<div class="flight_Search_boxed date_flex_area">
																	<div class="Journey_date">
																		<p>{{ $t("entry_date") }}</p>
																		<input type="date" v-model="start_date" />
																	</div>
																	<div class="Journey_date">
																		<p>{{ $t("departure_date") }}</p>
																		<input type="date" v-model="end_date" />
																	</div>
																</div>
															</div>
														</div>
														<div class="col-lg-4 col-md-6 col-sm-12 col-12">
															<div
																class="flight_Search_boxed dropdown_passenger_area"
															>
																<CountDropdownHotel />
															</div>
														</div>
														<!-- <div class="col-lg-4 col-md-6 col-sm-12 col-12">
															<div
																class="flight_Search_boxed dropdown_passenger_area"
															>
																<p>Guests</p>
																<div class="dropdown">
																	<button
																		class="dropdown-toggle"
																		data-toggle="dropdown"
																		type="button"
																		id="dropdownMenuButton1"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																	>
																		1 Guests
																	</button>
																	<div
																		class="dropdown-menu dropdown_passenger_info"
																		aria-labelledby="dropdownMenuButton1"
																	>
																		<div class="traveller-calulate-persons">
																			<div class="passengers">
																				<h6>Passengers</h6>
																				<div class="passengers-types">
																					<div class="passengers-type">
																						<div class="text">
																							<span class="count">2</span>
																							<div class="type-label">
																								<p>Adult</p>
																								<span>12+ yrs</span>
																							</div>
																						</div>
																						<div class="button-set">
																							<button type="button">
																								<i class="fas fa-plus"></i>
																							</button>
																							<button type="button">
																								<i class="fas fa-minus"></i>
																							</button>
																						</div>
																					</div>
																					<div class="passengers-type">
																						<div class="text">
																							<span class="count">0</span>
																							<div class="type-label">
																								<p class="fz14 mb-xs-0">
																									Children
																								</p>
																								<span
																									>2 - Less than 12 yrs</span
																								>
																							</div>
																						</div>
																						<div class="button-set">
																							<button type="button">
																								<i class="fas fa-plus"></i>
																							</button>
																							<button type="button">
																								<i class="fas fa-minus"></i>
																							</button>
																						</div>
																					</div>
																					<div class="passengers-type">
																						<div class="text">
																							<span class="count">0</span>
																							<div class="type-label">
																								<p class="fz14 mb-xs-0">
																									Infant
																								</p>
																								<span>Less than 2 yrs</span>
																							</div>
																						</div>
																						<div class="button-set">
																							<button type="button">
																								<i class="fas fa-plus"></i>
																							</button>
																							<button type="button">
																								<i class="fas fa-minus"></i>
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<span>Adult</span>
															</div>
														</div> -->
														<div class="top_form_search_button text-right">
															<button class="btn btn_theme btn_md">
																{{ $t("check_availability") }}
															</button>
														</div>
													</div>
												</form>
											</div>
											<div
												class="room_book_item"
												v-for="room in roomTypes"
												:key="room.id"
											>
												<div class="room_book_img">
													<img
														:src="room.main_image"
														alt="img"
														:width="customWidth"
														:height="customHeight"
														@click="modalToggle(room)"
													/>
												</div>
												<div class="room_booking_right_side">
													<div class="room_booking_heading">
														<h3>
															<router-link to="/room-booking">{{
																room.name
															}}</router-link>
														</h3>
														<div class="room_fasa_area">
															<ul>
																<li
																	v-for="amenity in room.amenities"
																	:key="amenity.name"
																>
																	<img
																		src="../../assets/img/icon/ac.png"
																		alt="icon"
																	/>{{ amenity.name }}
																</li>
															</ul>
														</div>
													</div>
													<div class="room_person_select">
														<h3>
															{{ formatNumber(room.fee_by_night) }}
															<sub>{{ $t("per_night") }}</sub>
														</h3>
														<select
															class="form-select"
															aria-label="Default select example"
														>
															<option selected>1</option>
															<option value="2">2</option>
															<option value="3">3</option>
															<option value="4">4</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="tour_details_boxed">
							<h3 class="heading_theme">{{ $t("property_location") }}</h3>
							<div class="map_area">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
								></iframe>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="col-lg-4">
					<div class="tour_details_right_sidebar_wrapper">
						<div class="tour_detail_right_sidebar">
							<div class="tour_details_right_boxed">
								<div class="tour_details_right_box_heading">
									<h3>Price</h3>
								</div>
								<div class="tour_package_bar_price">
									<h6><del>$ 35,500</del></h6>
									<h3>$ 30,500 <sub>/Per serson</sub></h3>
								</div> 

								<div class="tour_package_details_bar_list">
									<h5>Comodidades del Hotel</h5>
									<ul>
										<li>
											<i class="fas fa-circle"></i>Desayuno buffet según el
											Itinerario
										</li>
										<li>
											<i class="fas fa-circle"></i>Visita a lugares Históricos y
											Culturales
										</li>
										<li>
											<i class="fas fa-circle"></i>Tour de Paseo por la Ciudad
										</li>
										<li>
											<i class="fas fa-circle"></i>Todos los impuestos de peaje,
											estacionamiento, combustible, y asignaciones para
											conductores
										</li>
										<li>
											<i class="fas fa-circle"></i>Vehículo cómodo e higiénico.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>-->
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-lg-10 offset-lg-1">
					<div class="write_your_review_wrapper">
						<h3 class="heading_theme">{{ $t("write_your_review") }}</h3>
						<div class="write_review_inner_boxed">
							<form action="!#" id="news_comment_form">
								<div class="row">
									<div class="col-lg-6">
										<div class="form-froup">
											<input
												type="text"
												class="form-control bg_input"
												:placeholder="$t('full_name')"
											/>
										</div>
									</div>
									<div class="col-lg-6">
										<div class="form-froup">
											<input
												type="text"
												class="form-control bg_input"
												:placeholder="$t('email')"
											/>
										</div>
									</div>
									<div class="col-lg-12">
										<div class="form-froup">
											<textarea
												rows="6"
												:placeholder="$t('write_your_comments')"
												class="form-control bg_input"
											></textarea>
										</div>
										<div class="comment_form_submit">
											<button class="btn btn_theme btn_md">
												{{ $t("post_comment") }}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			ref="modal"
			class="modal fade"
			:class="{ show: active, 'd-block': active }"
			tabindex="-1"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-centered modal-lg">
				<div class="modal-content">
					<div class="modal-body map_modal_content">
						<div class="btn_modal_closed">
							<button
								type="button"
								data-bs-dismiss="modal"
								aria-label="Close"
								@click="modalToggle"
							>
								<i class="fas fa-times"></i>
							</button>
						</div>
						<swiper
							:slides-per-view="1"
							:space-between="25"
							:pagination="{ clickable: true }"
						>
							<swiper-slide
								v-for="(image, imageIndex) in selectedRoomType.images"
								:key="imageIndex"
							>
								<img
									:src="image"
									alt="Imagen de la habitación"
									class="carousel-image"
								/>
								<hr />
							</swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import CountDropdownHotel from "@/components/home/CountDropdownHotel.vue";
	import axios from "axios";
	import { mapState, mapActions } from "vuex";
	import { API_BASE_URL, PROPERTY_API } from "@/api-config";
	import "swiper/swiper-bundle.css";
	import Swal from "sweetalert2";

	export default {
		name: "HotelSearch",
		components: {
			CountDropdownHotel,
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				active: false,
				start_date: "",
				end_date: "",
				selectedRoomType: { images: [] },
				roomTypes: [],
				gallerySlides: [],
				slideIndex: 0,
				slides: [
					// { img: require("@/assets/img/hotel/small-1.png") },
					// { img: require("@/assets/img/hotel/small-2.png") },
					// { img: require("@/assets/img/hotel/small-3.png") },
					// { img: require("@/assets/img/hotel/small-4.png") },
					// { img: require("@/assets/img/hotel/small-5.png") },
					// { img: require("@/assets/img/hotel/small-6.png") },
				],
				property: {
					data: {
						name: "",
						address: "",
						description: "",
						images: "",
					},
				},
				amenities: [],
				customWidth: 480,
				customHeight: 240,
			};
		},
		mounted() {
			this.start_date = this.$route.query.start_date;
			this.end_date = this.$route.query.end_date;
			this.updateCapacity(this.$route.query.capacity);
			this.fetchProperty();
		},
		computed: {
			...mapState({
				capacity: (state) => state.form.capacity,
			}),
		},
		methods: {
			...mapActions("form", ["updateCapacity"]),
			showAlert(errorMessage) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: errorMessage,
				});
			},
			modalToggle(roomType) {
				const body = document.querySelector("body");
				this.active = !this.active;
				this.active
					? body.classList.add("modal-open")
					: body.classList.remove("modal-open");
				this.selectedRoomType = roomType;
			},
			formatNumber(number) {
				return new Intl.NumberFormat("es-ES", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				}).format(number);
			},
			move(n) {
				if (this.gallerySlides.length <= this.slideIndex + n) {
					this.slideIndex = 0;
				} else if (this.slideIndex + n < 0) {
					this.slideIndex = this.gallerySlides.length - 1;
				} else {
					this.slideIndex += n;
				}
			},
			currentSlide(index) {
				this.slideIndex = index;
			},
			async fetchProperty() {
				let params = {
					start_date: this.start_date,
					end_date: this.end_date,
					capacity: this.capacity,
				};
				axios
					.get(`${API_BASE_URL}${PROPERTY_API}${this.$route.params.id}`, {
						params: params,
					})
					.then((response) => {
						this.property = response.data;

						this.property.data.images.forEach((image) => {
							this.gallerySlides.push({
								img: image,
							});
						});

						this.roomTypes = this.property.data.roomTypes;

						this.amenities = this.property.data.amenities;

						this.$router.push({
							name: "hotel-details",
							params: { id: this.$route.params.id },
							query: params,
						});
					})
					.catch((error) => {
						if (
							error.response &&
							error.response.data &&
							error.response.data.message
						) {
							const errorMessage = error.response.data.message;
							this.showAlert(errorMessage);
						} else {
							this.showAlert("Error desconocido");
						}
					});
			},
		},
	};
</script>
<style scoped>
	.flight_Search_boxed {
		height: 95px;
	}
	.carousel-image {
		width: 1280px;
		height: 768px;
	}
</style>
