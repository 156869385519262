import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSelect from "vue-select";
import store from "./store";
import { createI18n } from "vue-i18n";

import "vue-select/dist/vue-select.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// Main CSS Add
import "./assets/app.css";
import i18n from "@/translates/translationService";

createApp(App)
	.use(store)
	.use(router)
	.component("v-select", VueSelect)
	.use(i18n)
	.mount("#app");
